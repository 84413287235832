import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Downloader from "../components/downloader"
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby'
import Faqs from "../components/faqs"
import HowTo from "../components/howTo"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const IndexPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("Online Free Video Downloader")} description={t("Download videos from different sources in video (MP4, webm), Audio (MP3, M4A) or convert to MP3 without installing any additional programs!")} isIndex={true} />
      <Downloader title={t("Online Free Video Downloader")} description={t("Download Youtube, TikTok, Instagram, Twitter Videos Online Without Watermark")} />
      <Container>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("Supported Platforms To Download Videos Online")}</h2>
            <h5>{t("Instagram Video Downloader")}</h5>
            <p>{t("With this Snapzy, saving Instagram videos has never been easier.")}. <Link to="/instagram-video-downloader/">{t("Instagram Video Downloader")}</Link> {t("helps you to avoid all of the hassles associated with saving videos by downloading them from this platform. The account from which you're extracting the video, on the other hand, must be public. Otherwise, this online downloader will not be able to fetch or download videos from the private account.")}</p>
            <h5>{t("Youtube Video Downloader")}</h5>
            <p>{t("A well-known video-sharing website called Youtube has videos in almost every genre.")} <Link to="/youtube-video-downloader/">{t("Youtube Video Downloader")}</Link> {t("is your best option if you come across a video on this platform and want to save it for later viewing without having to access the internet. Simply copy the video's URL, paste it into this video downloader, and press the download button to download Youtube videos. This tool will process your request, and you'll instantly receive the desired video in your device's local storage.")}</p>
            <h5>{t("Twitter Video Downloader")}</h5>
            <p>{t("Do you want to avoid the challenges that come with downloading videos from Twitter? If so, you can use our")} <Link to="/twitter-video-downloader/">{t("Twitter Video Downloader")}</Link> {t("to save videos from this well-known social network. For downloading videos from Twitter, you don't need to install any software because you can easily access this web-based utility through the web browser on your device.")}</p>
            <h5>{t("TikTok Video Downloader Without Watermark")}</h5>
            <p>{t("One of the most popular social media platforms today is TikTok. TikTok videos can now be downloaded without a watermark using our")} <Link to="/tiktok-video-downloader/">{t("TikTok Video Downloader Without Watermark")}</Link>. {t("Simply paste the URL into the TikTok share box to download your preferred video.")}</p>
            <h5>{t("Likee Video Downloader Without Watermark")}</h5>
            <p>{t("Because TikTok's main competitor is so popular, we also allow you to save videos from our website. You can download videos from the Likee app or website by simply copying and pasting the share link into the input area on")} <Link to="/likee-video-downloader/">{t("Likee Video Downloader Without Watermark")}</Link>. {t("After that, simply click on download.")}</p>
            <h5>{t("Facebook Video Downloader")}</h5>
            <p>{t("It is a straightforward web")} <Link to="/facebook-video-downloader/">{t("Facebook Video Downloader")}</Link> {t("tool that allows you to download any video content from the Facebook social networking site. You can save any format video - post, streaming live, narrative - from one second to five hours long to your PC, Mac, Android, or iPhone with this software. All you have to do is copy and paste the link to the information you want.")}</p>
            <h5>{t("Reddit Video Downloader With Audio")}</h5>
            <p>{t("Using the ")} <Link to="/reddit-video-downloader/">{t("Free Online Reddit Downloader With Audio")}</Link>, {t("you may download Reddit videos in MP4/MP3 format and save them to your mobile gallery or PC in high quality. Snapzy has created an internet service for downloading Reddit videos quickly and easily. Simply provide us with the link to the Reddit post where the video is available, and we will download the video in several resolutions and formats.")}</p>
            <h5>{t("SoundCloud MP3 Downloader")}</h5>
            <p><Link to="/soundcloud-music-downloader/">{t("SoundCloud Music Downloader")}</Link> {t("is a webapp that allows you to download SoundCloud tracks, songs, and music in MP3 format online. Use this SoundCloud downloader to download high-quality mp3 files from SoundCloud at speeds of 128kbps and 320kbps. Using this SoundCloud downloader, you can only download SoundCloud tracks that are approved for downloading by SoundCloud after the track owner has granted permission to download. To download a SoundCloud mp3 track, enter its url/link in the top text field and press the 'Download' button. It converts Soundcloud tracks to MP3.")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("What is Snapzy Video Downloader")}?</h2>
            <p>{t("Looking for an easy way to download videos from your favorite websites? Our online video downloader allows you to quickly and easily download videos from YouTube, TikTok, Facebook, and more. With support for a variety of formats and high-quality options, you can save your favorite videos to your device and watch them anytime, anywhere. Try our video downloader today and start saving your favorite videos in just a few clicks!")}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2 className="text-center pt-5 pb-2">{t("What Features Should a Best Video Downloader Have")}?</h2>
            <p>{t("Here are some features that you may find in an online free video downloader")}:</p>
            <ol>
              <li><strong>{t("Compatibility with multiple video platforms")}:</strong> {t("An online free video downloader should be able to download videos from a variety of popular video platforms, such as YouTube, TikTok, Facebook, Instagram, and more")}</li>
              <li><strong>{t("Multiple video formats")}:</strong> {t("An online free video downloader should offer a range of video formats to choose from, such as MP4, AVI, MKV, MOV, and more. This allows you to download the video in a format that is compatible with your device or software.")}</li>
              <li><strong>{t("Multiple video quality options")}:</strong> {t("An online free video downloader should offer a range of video quality options, such as 360p, 480p, 720p, 1080p, and more. This allows you to choose the quality level that best suits your needs.")}</li>
              <li><strong>{t("Fast download speeds")}:</strong> {t("An online free video downloader should be able to download videos quickly and efficiently, without any delays or interruptions.")}</li>
              <li><strong>{t("User-friendly interface")}:</strong> {t("An online free video downloader should have a simple and easy-to-use interface, so that you can quickly and easily download videos.")}</li>
              <li><strong>{t("No registration or installation required")}:</strong> {t("An online free video downloader should not require you to register or install any software in order to use it.")}</li>
              <li><strong>{t("No watermarks")}:</strong> {t("An online free video downloader should not add any watermarks or other overlaid graphics to the downloaded videos.")}</li>
              <li><strong>{t("Safe and secure")}:</strong> {t("An online free video downloader should be safe and secure, protecting your privacy and personal information.")}</li>
            </ol>
          </Col>
        </Row>
      </Container>
      <HowTo />
      <Faqs />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
